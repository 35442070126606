import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';


@Injectable()
export class MessagingSevice {
  constructor(private http: HttpClient) { }

 GetIntercomUser(email: string): Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization':'Token dG9rOjA2YzUxMGZkX2JiN2VfNDIzN19iMDVjXzdlYmU2ODIxM2JlNDoxOjA='
    })
  };
 // headers.append('Accepts','application/json');
 // headers.append('Authorization', 'Token dG9rOjA2YzUxMGZkX2JiN2VfNDIzN19iMDVjXzdlYmU2ODIxM2JlNDoxOjA=');
  console.log('HEaders', JSON.stringify(httpOptions));
  return this.http.get(`https://api.intercom.io/users?email=${email}`, httpOptions);
}
}
