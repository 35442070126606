import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgModule, Component, Input, Output, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {Observable } from "rxjs";
import {map } from "rxjs/operators";

@Component({
    selector: 'file-upload',
    template:  `<input *ngIf="showFileNameInput" id="uploadFile" class="upload-file form-control" (change)=readThis($event) placeholder="Choose File" [(ngModel)]="selectedFileName" disabled="disabled" />
                <div class="fileUpload btn btn-primary">
                    <span>{{uploadButtonText}}</span>
                    <input type="file" class="upload" accept="*" (change)="changeListener($event)">
                </div>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {
    selectedFileName: string = null;
    file = null;
    @Input() showFileNameInput: boolean;
    @Input() uploadButtonText: string;
    constructor(private http: HttpClient) { }
    writeValue(value: any) {
       //Handle write value
    }
    propagateChange = (_: any) => {
        const spliced = _.split(',');
        const h = spliced[0];
        spliced.shift();
        let send={
            header: h,
            body: spliced.join('')
          };
      const url = 'https://gateway.backendapis.net/node/file/multipart?name='+this.selectedFileName
      let formData:FormData = new FormData();
      formData.append('uploadFile', this.file, this.file.name);
      let header = new Headers();
      header.append('Content-Type', 'multipart/form-data');
      header.append('Accept', 'application/json');
      //let options = new RequestOptions({ headers: headers });
      const  headers = new  HttpHeaders().set('Content-Type', 'multipart/form-data');
        headers.set('Accept', 'application/json');
      this.http.post(url, send.body, { headers })
          .subscribe(
              data => {
                console.log(JSON.stringify(data));
              },
              error => console.log(error)
          );
     };
    registerOnChange(fn) {
        this.propagateChange = fn;
    }
    registerOnTouched() { }

    changeListener($event): void {
        this.readThis($event.target);
    }
    readThis(inputValue: any): void {
        this.file = inputValue.files[0];
        const myReader: FileReader = new FileReader();

        myReader.onloadend = (e) => {
            this.selectedFileName = this.file.name;
            this.propagateChange(myReader.result);
        }
        myReader.readAsDataURL(this.file);
    }
}
