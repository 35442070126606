import { bool } from 'aws-sdk/clients/signer';
interface LastLocation {
  point: string;
  updated_at: Date;
}
interface Device {
  model: string;
  OS: string;
}
export class User {
  constructor (public id: number, public first_name: string, public last_name: string,
              public email: string, public is_deleted: boolean, public locale: string,
              public  created_at: Date,  updated_at: Date, public phone_number: string,
              public country_id: number, public account_id?:number, public dashboard_url?:string,
              public photo?:string, public  is_blocked?: boolean, public role?: string) {}
  name() { return `${this.first_name} ${this.last_name}`; }
 }

 export class WorkerUser extends User {
   status_message?: any;
   device: Device;
   country_id: number;
   region_id: number;
   city_id: number;
   district_id?: any;
  // construtor
  constructor (id: number,  first_name: string,  last_name: string,
     email: string,  is_deleted: boolean,  locale: string,
     public last_location: LastLocation, public fbuid: any, public gender: number,
     public birthdate: Date, public is_blocked: boolean, public phone_number: string,
      created_at: Date,  updated_at: Date, public nationality_id?: number) {
   super(id,  first_name,  last_name,
    email,  is_deleted,  locale,
     created_at,  updated_at,phone_number, null );
  }

  }

